type FullPageLoaderProps = {
  text: string | null;
};

function FullPageLoader({ text }: FullPageLoaderProps) {
  return (
    <div
      className="w-full h-full fixed top-0 left-0 bg-gray-400/50 z-[100000] overflow-hidden
    flex justify-center items-center flex-col"
    >
      <div className="flex flex-wrap items-center gap-6">
        <div className="inline-flex size-2 rounded-full opacity-75 animate-ping bg-custom-500"></div>
        <div className="inline-flex size-4 rounded-full opacity-75 animate-ping bg-custom-500"></div>
        <div className="inline-flex size-6 rounded-full opacity-75 animate-ping bg-custom-500"></div>
        <div className="inline-flex size-8 rounded-full opacity-75 animate-ping bg-custom-500"></div>
      </div>
      {text && <h1 className="mt-3">{text}</h1>}
    </div>
  );
}

export default FullPageLoader;
