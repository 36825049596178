import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIClient } from "helpers/api_helper";
import useFullPageLoader from 'hooks/useFullPageLoader';
import ProfilePic from "../../assets/images/users/user-dummy-img.jpg";

const DataView = () => {
  const { id } = useParams();  // Extract ID from URL
  const [user, setUser] = useState<Record<string, any> | null>(null);
  const api = new APIClient();
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const getUserData = async () => {
    showLoader();
    try {
      // Fetch data from the API using the ID from URL
      let response = await api.get(`restify/users/${id}`, {});
      setUser(response.data.attributes);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      hideLoader(); // Hide loader once data fetch completes
    }
  };

  useEffect(() => {
    getUserData();
   
  }, [id]); 


  interface UserDataFieldProps {
    label: string;
    value: any;
    id?: string;
  }
  
  const UserDataField: React.FC<UserDataFieldProps> = ({ label, value, id }) => (
    <div id={id} className="flex justify-between items-center">
      <span className="text-gray-700 font-semibold">{label}:</span>
      <span className="text-gray-900">{value ? value : "Not provided"}</span>
    </div>
  );

  if (!user) return loader;  
  // console.log("this page is rendering");

  return (
    <>
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md mt-10">
  {/* Profile Section */}
  <div id="profile-section" className="flex justify-center mb-8">
    <img
      className="w-32 h-32 rounded-full"
      src={user.profile_photo_path ? user.profile_photo_path : ProfilePic}
      alt="Profile Photo"
    />
  </div>

  {/* Personal Information Section */}
  <section id="personal-info-section" className="mb-8">
    <h2 className="text-2xl font-bold mb-4">Personal Information</h2>
    <div className="space-y-4">
    <UserDataField id="username" label="Username" value={user?.username} />
    <UserDataField id="first_name" label="First Name" value={user?.first_name} />
    <UserDataField id="last_name" label="Last Name" value={user?.last_name} />
    <UserDataField id="gender" label="Gender" value={user?.gender} />
    <UserDataField id="age" label="Age" value={user?.age} />
    <UserDataField id="dob" label="Date of Birth" value={user?.date_of_birth} />
    <UserDataField id="citizenship" label="Citizenship" value={user?.citizenship} />
    <UserDataField id="firstLang" label="First Language" value={user?.firstLang} />
    <UserDataField id="secondLang" label="Second Language" value={user?.secondLang} />
    </div>
  </section>

  {/* Contact Information Section */}
  <section id="contact-info-section" className="mb-8">
    <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
    <div className="space-y-4">
    <UserDataField id="email" label="Email" value={user?.email} />
    <UserDataField id="mobile" label="Mobile No" value={user?.mobileNo} />
    <UserDataField id="contactNo" label="Contact No" value={user?.contactNo} />
    </div>
  </section>

  {/* Location Information Section */}
  <section id="location-info-section" className="mb-8">
    <h2 className="text-2xl font-bold mb-4">Location Information</h2>
    <div className="space-y-4">
    <UserDataField id="latitude" label="Latitude" value={user?.lat} />
    <UserDataField id="longitude" label="Longitude" value={user?.lng} />
    <UserDataField id="address" label="Address" value={user?.address} />
    <UserDataField id="city" label="City" value={user?.city} />
    <UserDataField id="country" label="Country" value={user?.country} />
    <UserDataField id="postCode" label="Post Code" value={user?.postCode} />
    </div>
  </section>

  {/* Religious Information Section */}
  <section id="religious-info-section" className="mb-8">
    <h2 className="text-2xl font-bold mb-4">Religious Information</h2>
    <div className="space-y-4">
    <UserDataField id="halal" label="Halal" value={user?.halal} />
    <UserDataField id="hijab" label="Hijab" value={user?.hijab} />
    <UserDataField id="religiousness" label="Religiousness" value={user?.religiousness} />
    <UserDataField id="salaah" label="Salaah" value={user?.salaah} />
    <UserDataField id="sect" label="Sect" value={user?.sect} />
    </div>
  </section>

  {/* Additional Information Section */}
  <section id="additional-info-section" className="mb-8">
    <h2 className="text-2xl font-bold mb-4">Additional Information</h2>
    <div className="space-y-4">
    <UserDataField id="education" label="Education Level" value={user?.educationLevel} />
    <UserDataField id="subjectStudied" label="Subject Studied" value={user?.subjectStudied} />
    <UserDataField id="employment" label="Employment" value={user?.employment} />
    <UserDataField id="profession" label="Profession" value={user?.profession} />
    <UserDataField id="income" label="Income" value={user?.income} />
    <UserDataField id="isAdminVerified" label="Admin Verified" value={user?.isAdminVerified} />
    <UserDataField id="isOnline" label="Is Online" value={user?.isOnline} />
    <UserDataField id="maritalStatus" label="Marital Status" value={user?.maritalStatus} />
    <UserDataField id="haveChildren" label="Have Children" value={user?.haveChildren} />
    <UserDataField id="description" label="Description" value={user?.description} />
    <UserDataField id="height" label="Height" value={user?.height} />
    <UserDataField id="hair" label="Hair" value={user?.hair} />
    <UserDataField id="eyeColour" label="Eye Colour" value={user?.eyeColour} />
    <UserDataField id="beard" label="Beard" value={user?.beard} />
    <UserDataField id="smoker" label="Smoker" value={user?.smoke} />
    <UserDataField id="physDisabled" label="Physically Disabled" value={user?.disabilities} />
    <UserDataField id="disabilitiesDetails" label="Disability Details" value={user?.disabilitiesDetails} />
    <UserDataField id="lookinFor" label="Looking For" value={user?.lookinFor} />
    </div>
  </section>
</div>

    </>
  )
}

export default DataView;
