import { useState } from "react";
import FullPageLoader from "./../Common/Components/FullPageLoader";

const useFullPageLoader = (): [
  JSX.Element | null,
  (text?: string | null) => void,
  () => void
] => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState<string | null>(null);

  return [
    loading ? <FullPageLoader text={text} /> : null,
    (text: string | null = null) => {
      document.body.classList.add("overflow-hidden");
      setText(text);
      setLoading(true);
    },
    () => {
      document.body.classList.remove("overflow-hidden");
      setLoading(false);
    },
  ];
};

export default useFullPageLoader;
